<div class="chat-screen"  #chatScreenSender>
    
    <ng-container *ngFor="let msg of messages(); let i = index;">
        <p-menu styleClass="options" [baseZIndex]="99999999"  #menu1 [popup]="true" [model]="buildMenuItems(msg)" [appendTo]="'body'"></p-menu>


        <div class="full message-sender"  [ngClass]="{'snd_row': msg?.senderId === this.user?.aspUserId, 
  '': msg?.senderId === this.user?.aspUserId}">
        
            <div class="message flex gap-2" [ngClass]="{'msg-me': msg?.senderId === this.user?.aspUserId, 
                'msg-other': msg?.senderId !== this.user?.aspUserId, 
            'longer': isBubbleMode()}">
            <!-- @if (shouldShowOptionsMenu(msg)) { -->
                <div class="ml-auto absolute" >
                    <button pButton pRipple icon="pi pi-ellipsis-v"
                        class="p-button-text p-button-plain p-button-rounded p-buttom-sm options-btn" 
                        (click)="menu1.toggle($event); selectedMessage = msg; selectedMessageIndex = i"></button>
                    
                </div>
                <!-- } -->

                <ng-container *ngIf="msg?.isFileFromLibrary || msg?.isUploadFile; else notFile">
                    <ng-container *ngIf="isImage(msg?.content); else notImage">
                        <a class="p-button-rounded p-button-info" [href]="libraryService.getFileFullPath(msg?.content)"
                            target="_blank">
                            <img [src]="libraryService.getFileFullPath(msg?.content)" alt="Image" class="image-class" />
                        </a>
                    </ng-container>
                    <ng-template #notImage>
                        <a class="p-button-rounded p-button-info  flex gap-2 align-items-center justify-content-center"
                            [ngClass]="{'text-white': msg?.senderId === this.user?.aspUserId }"
                            [href]="libraryService.getFileFullPath(msg?.content)" target="_blank"><i
                                class="pi pi-file"></i>
                            {{libraryService.getFileName(msg?.content)}}
                        </a>
                    </ng-template>
                </ng-container>

                <ng-template #notFile>
                    <div  [innerHTML]="msg?.content || ''"></div>

                </ng-template>

            </div>
            <div class="flex" [ngClass]="{'msg-me-time': msg?.senderId === this.user?.aspUserId, 
  'msg-other-time': msg?.senderId !== this.user?.aspUserId}">
  <span *ngIf="msg?.senderId !== this.user?.aspUserId" >{{getUserFirstName(msg?.senderId)}} - </span> &nbsp;
  <span>{{msg?.timestamp?.seconds * 1000 | date: 'medium'}}</span>
                <!-- <div class="avatar" (click)="deleteMessage(msg)">
                    {{getUserFullNameAndInitials(msg?.senderId).initials}}</div> -->
            </div>

            <!-- <img class="dp" [src]="this.userService.getUserPhoto(this.authService.getLoggedInUser())" alt="" /> -->

        </div>

    </ng-container>

</div>