import { Injectable, signal } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthData, User, UserAvailability, UserAvailabilityOff, UserRole } from '../models/user.model';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { Teacher, TeacherStatus, TeacherStatusResponse } from '../models/teacher.model';
import { map, switchMap } from 'rxjs/operators';
import { UserService } from './user.service';
import { ToastService } from './toast.service';
const BACKEND_URL = environment.apiUrl + "/Auth/";
const BACKEND_TEACHER_URL = environment.apiUrl + "/Teacher/";
const BACKEND_LMS_URL = environment.apiUrl + "/LMS/";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isAuthenticated = false;
  isAuthenticatedSignal = signal(false);
  firebaseLogoutEvent = signal(false);
  private token?: string | null;
  private user?: User | null;
  private tokenTimer: any;
  private userId?: string | null;
  public authStatusListener = new Subject<boolean>();
  public teacherStatus?: string;
  UserRoles = UserRole;
  private _userId: string | null = null; // private property to store userId value

  private showRegistrationSuccessfullMessage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showRegistrationSuccessfullMessage: Observable<boolean> = this.showRegistrationSuccessfullMessage$.asObservable();

  public disabledProfileRoutes = false;
  constructor(
    private http: HttpClient,
    private router: Router,
    private toastService: ToastService,
    private userService: UserService,
    // private _externalAuthService: SocialAuthService
  ) { }

  get isStudent(): boolean {
    return this.getUserRole() === UserRole.STUDENT;
  }

  get isTeacher(): boolean {
    return this.getUserRole() === UserRole.TEACHER;
  }

  get teachingLanguage(): string {
    return this.getUser()!.teachingLanguage!;
  }

  getToken() {
    return this.token;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getUserId() {
    return this.userId || this._userId; // return userId from private property if it exists, otherwise from local storage
  }

  setUserId(userId: string) {
    this._userId = userId; // set userId value in private property
  }

  getUserRole() {
    if (this.token) {
      const tokenInfo = this.getDecodedAccessToken(this.token!); // decode token
      return tokenInfo.role
    }
    return null
  }

  getLoggedInUser() {
    const authInformation = this.getAuthData();
    if (authInformation) {

      let user = JSON.parse(authInformation?.user!);
      // // Mutate timeZone
      // if (user.timeZone === 'Etc/UTC') {
      //   user.timeZone = "Europe/London";
      // }
      return user;
    }
    return {}
  }

  setAuthStatusListener(status: boolean) {
    this.authStatusListener.next(status);
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  createUser(email: string, password: string, endpoint: string) {
    return this.http.post(BACKEND_LMS_URL + endpoint, {
      email: email,
      pwd: password
    });
  }

  confirmUser(userId: string, code: string) {
    this.http.post(BACKEND_LMS_URL + 'ConfirmEmailAccount?userId = ' + userId + '&code=' + code + '', { userId: userId, code: code })
      .subscribe(res => {
        if (res) {
          this.toastService.setShowToastmessage({
            severity: 'success',
            summary: '',
            detail: 'Your account has been confirmed.'
          });
          this.router.navigateByUrl('/auth', { replaceUrl: true })
        }
      })
  }

  forgot(email: string) {
    this.http.post<User>(BACKEND_LMS_URL + "sendForgotPassCode?UsernameResetPass=" + email, {})
      .subscribe(async response => {

      })
  }

  confirmForgot(email: string, code: string, password: string) {
    let c = {
      code: code,
      email: email,
      pwd: password,
      pwdRetype: password
    }
    return this.http.post<User>(BACKEND_URL + "SetPassword", c);
  }

  login(email: string, password: string) {
    const authData: AuthData = {
      username: email,
      password: password
    };

    return this.http.post<User>(BACKEND_LMS_URL + "authenticate", authData)
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  setToken(tokenValue: string | undefined) {
    this.token = tokenValue;
  }

  setIsAuthenticated(value: boolean) {
    this.isAuthenticated = value;
    this.isAuthenticatedSignal.set(value);
  }

  setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000)
  }

  setDisabledProfileRoutes(value: boolean) {
    this.disabledProfileRoutes = value;
  }

  setShowRegistrationSuccessfullMessage(value: boolean) {
    this.showRegistrationSuccessfullMessage$.next(value);
  }

  saveAuthData(token: string, expirationDate: Date, userId: string, user: User, role: string) {
    localStorage.setItem("token", token);
    localStorage.setItem("expiration", expirationDate.toISOString());
    localStorage.setItem("userId", userId);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("role", role);
    if (userId) {
      this._userId = userId; // set userId value in private property
    }
  }

  async autoAuthUser() {

    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      const tokenInfo = this.getDecodedAccessToken(this.token!); // decode token
      this.isAuthenticated = true;
      this.userId = authInformation.userId;
      this.user = JSON.parse(authInformation.user!)
      if (authInformation.userId) {
        this._userId = authInformation.userId; // set userId value in private property
      }
      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);
    }
  }

  private getAuthData() {
    const token = localStorage.getItem("token");
    const expirationDate = localStorage.getItem("expiration");
    const userId = localStorage.getItem("userId");
    const user = localStorage.getItem("user");
    const teacherStatus = localStorage.getItem("teacherStatus");
    const role = localStorage.getItem("role");
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      userId: userId,
      user: user,
      teacherStatus: teacherStatus,
      role: role
    }
  }

  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    this.userId = null;
    this.user = null;
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(['/auth/login']);
  }

  private clearAuthData() {
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
    localStorage.removeItem("userId");
    localStorage.removeItem("user");
    localStorage.removeItem("role");
    localStorage.removeItem("teacherStatus");
    this.teacherStatus = undefined;
    this._userId = null;
  }

  public isUserInfoFullfileld() {
    if (
      this.user?.email === "" ||
      this.user?.firstName === "" ||
      this.user?.lastName === "" ||
      this.user?.skype === "" ||
      this.user?.timeZone === "" ||
      this.user?.email === "Not Given" ||
      this.user?.firstName === "Not Given" ||
      this.user?.lastName === "Not Given" ||
      this.user?.skype === "Not Given" ||
      this.user?.timeZone === "Not Given"
    ) {
      return false;
    }
    return true;
  }

  public getTeacherStatus(): Observable<TeacherStatusResponse> {

    return this.http.get<TeacherStatusResponse>(BACKEND_LMS_URL + "GetTeacherApplicationStatus");
  }

  public getTeacherStatusPromise(): Promise<TeacherStatusResponse | undefined>  {

    return this.http.get<TeacherStatusResponse>(BACKEND_LMS_URL + "GetTeacherApplicationStatus").toPromise();
  }

  public setTeacherStatus(status: any) {
    this.teacherStatus = status;
  }

  public getUserPhoto() {
    const authInformation = this.getAuthData();
    if (authInformation) {
      this.user = JSON.parse(authInformation?.user!);
      let photo = (this.user?.photo && this.user?.photo !== 'Not Given') ? environment.apiUrl + "/" + this.user?.photo : "/assets/images/avatar.svg";
      return photo;
    }
    return ""
  }

  public getUser() {
    const authInformation = this.getAuthData();
    this.user = JSON.parse(authInformation?.user!);
    return this.user;
  }
  //TODO GOOGLE LOGIN

  // public signInWithGoogle = ()=> {
  //   return this._externalAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  // }
  // public signOutExternal = () => {
  //   this._externalAuthService.signOut();
  // }

  
  saveLeadDynoAffiliateIdFromRoute(next: ActivatedRouteSnapshot) {
    const leadDynoAffiliateId = next.queryParams['afmc'];

    if (leadDynoAffiliateId !== null && leadDynoAffiliateId !== undefined) {
      console.log('leadDynoAffiliateId', leadDynoAffiliateId);
      localStorage.setItem('leadDynoAffiliateId', leadDynoAffiliateId);
    }
  }
  
}
