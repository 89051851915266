import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, inject, input, Output, signal, ViewChild } from '@angular/core';
import { AuthService } from "src/app/core/services/auth.service";
import { LibraryService } from "src/app/core/services/library.service";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MenuItem } from "primeng/api";
import { MenuModule } from "primeng/menu";
import { ButtonModule } from "primeng/button";
import { Clipboard } from '@angular/cdk/clipboard';
import { Message } from "src/app/core/services/chat.service";

@Component({
    selector: 'app-chat-messages-display',
    standalone: true,
    imports: [
        CommonModule,
        MenuModule,
        ButtonModule,
    ],
    templateUrl: './chat-messages-display.component.html',
    styleUrl: './chat-messages-display.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessagesDisplayComponent {
    @ViewChild('chatScreenSender') chatScreenSender!: ElementRef;

    libraryService = inject(LibraryService);
    authService = inject(AuthService);
    sanitizer = inject(DomSanitizer);
    messages = input([] as any[]);
    isBubbleMode = input(false);
    chatsList = input([] as any[]);
    @Output() deleteMessageSelected = new EventEmitter<[any, any]>();
    @Output() editMessageSelected = new EventEmitter<[any, any]>();
    @Output() chatScreenSenderHeight = new EventEmitter<number>();
    clipboard = inject(Clipboard);
    user = this.authService.getLoggedInUser();
    selectedMessage: Message = {} as Message;
    selectedMessageIndex = 0;
    items: MenuItem[] | undefined = [
        {
            // label: 'Options',
            items: [
                {
                    label: 'Edit',
                    icon: 'pi pi-pencil',
                    command: (event) => {
                        console.log(this.messages().length);
                        this.editMessageSelected.emit([this.selectedMessage, this.messages().length - 1 === this.selectedMessageIndex]);
                    }
                },
                {
                    label: 'Copy',
                    icon: 'pi pi-copy',
                    command: (event) => {
                        const textContent = this.selectedMessage.content.replace(/<[^>]*>/g, '');

                        this.clipboard.copy(textContent);
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    command: (event) => {
                        this.deleteMessageSelected.emit([this.selectedMessage, this.messages().length - 1 === this.selectedMessageIndex]);
                    }
                }
            ]
        }
    ];
    filteredItems: MenuItem[] = [
        {
            // label: 'Options',
            items: []
        }
    ];

    @HostListener('window:resize', [])
    onResize() {
        this.updateElementHeight();
    }
    updateElementHeight() {
        if (this.chatScreenSender) {
            console.log(this.chatScreenSender.nativeElement.offsetHeight);
            this.chatScreenSenderHeight.emit(this.chatScreenSender.nativeElement.offsetHeight);
            // this.elementHeight = this.chatScreenSender.nativeElement.offsetHeight;
        }
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.updateElementHeight();
    }
    getUserFullNameAndInitials(aspUserId: string): any | null {
        for (let classroom of this.chatsList()) {
            // Check if the teacher matches the aspUserId
            if (classroom.teacher && classroom.teacher.aspUserId === aspUserId) {
                const fullName = classroom.teacher.firstName + ' ' + classroom.teacher.lastName;
                const initials = classroom.teacher.firstName.charAt(0) + classroom.teacher.lastName.charAt(0);
                return { fullName, initials };
            }

            // Check if any student matches the aspUserId
            for (let student of classroom.classroomStudents) {
                if (student.aspUserId === aspUserId) {
                    const fullName = student.firstName + ' ' + student.lastName;
                    const initials = student.firstName.charAt(0) + student.lastName.charAt(0);
                    return { fullName, initials };
                }
            }
        }
        return null; // Return null if no match is found
    }

    getUserFirstName(aspUserId: string): string | null {
        for (let classroom of this.chatsList()) {
            // Check if the teacher matches the aspUserId
            if (classroom.teacher && classroom.teacher.aspUserId === aspUserId) {
                return classroom.teacher.firstName; // Return full firstName
            } else {
                for (let student of classroom.classroomStudents) {
                    if (student.aspUserId === aspUserId) {
                        return student.firstName; // Return full firstName
                    }
                }
            }

            // Check if any student matches the aspUserId

        }
        return null; // Return null if no match is found
    }


    isImage(filePath: string): boolean {
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.svg', '.tiff', '.ico', '.jfif', '.avif'];
        const fileExtension = filePath.split('.').pop()?.toLowerCase();
        return imageExtensions.includes(`.${fileExtension}`);
    }

    deleteMessage(msg: any) {
        this.deleteMessageSelected.emit(msg);
    }

    sanitizeContent(msg: string) {
        return this.sanitizer.bypassSecurityTrustHtml(msg);
    }

    shouldShowOptionsMenu(msg: any) {
        return msg?.senderId === this.user?.aspUserId && !(msg?.isFileFromLibrary || msg?.isUploadFile);
    }

    buildMenuItems(msg: any) {
        if ((msg?.isFileFromLibrary || msg?.isUploadFile)) {
            this.filteredItems![0].items = this.items![0].items!.filter(item => item.label !== 'Edit');
            return this.filteredItems;
        }
        return this.items;
    }
}
