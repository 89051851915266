import { Injectable, inject, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { FieldValue, serverTimestamp, Timestamp } from 'firebase/firestore';
import * as moment from 'moment';
import { Observable, combineLatest, defer, firstValueFrom, forkJoin, from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { UserService } from './user.service';
import { GeneralService } from './general.service';
import { environment } from 'src/environments/environment';
const BACKEND_URL = environment.apiUrl;
const BACKEND_LMS_URL = environment.apiUrl + "/LMS/";
import { getAuth, signInAnonymously } from "firebase/auth";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from './auth.service';

interface Chat {
  createdAt: string;
  lastMessage: string;
  lastMessageAt: FieldValue;
  lastMessageFrom: string;
  lastMessageFromName: string;
  lastMessageFromImage: string;
  lastMessageIsFile: boolean;
  lastMessageDeleted: boolean;
  lastMessageId: string;
  isProcessed: boolean;
  lastProcessedMessageId: string;
  classroomId: string;
  unreadCounts?: any;
}

export interface ChatUserStatus {
  id?: string;
  status: 'online' | 'offline';
  lastOnlineTime: FieldValue;
}


export interface Message {
  id?: string;
  senderId: string;
  content: string;
  timestamp: FieldValue;
  isFileFromLibrary: boolean;
  isUploadFile: boolean;
  // readStatus: {}
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  userService = inject(UserService);
  generalService = inject(GeneralService);
  authService = inject(AuthService);
  isChatEnabled = signal(environment.isChatEnabled);
  showUploadFile = signal(false);
  unreadCount = signal(0);
  currentRoom = signal({} as any);
  showBubbleChat = signal(false);
  private dbPath = '/chats';
  private dbUnreadPath = '/chats';
  chatsRef: AngularFirestoreCollection<Chat>;
  private usersRef: AngularFirestoreCollection<ChatUserStatus> = this.db.collection('users');
  readCount = 0;
  readonly secondsToCheckOnlineStatus = 10;

  user = this.authService.getLoggedInUser();

  constructor(private db: AngularFirestore, private afAuth: AngularFireAuth, private http: HttpClient) {
    this.chatsRef = db.collection(this.dbPath);
    this.usersRef = db.collection('/users');

    this.db.firestore.enablePersistence()
      .catch(err => {
        if (err.code == 'failed-precondition') {
          console.error('Multiple tabs open, persistence can only be enabled in one tab at a time.');
        } else if (err.code == 'unimplemented') {
          console.error('The current browser does not support all of the features required to enable persistence.');
        }
      });
  }


  deleteChatFilesFromLibraryFolder(fileUrl: any): Observable<any> {
    console.log(fileUrl)
    return this.http.post<any>(BACKEND_LMS_URL + "DeleteChatFilesFromLibraryFolder?fileUrl=" + fileUrl, {})
  }

  // Sign in anonymously
  signInAnonymously(): Observable<any> {
    return new Observable(observer => {
      this.afAuth.signInAnonymously()
        .then(userCredential => {
          console.log('Signed in anonymously:', userCredential.user);
          observer.next(userCredential);
          observer.complete();
        })
        .catch(error => {
          console.error('Error signing in anonymously:', error);
          observer.error(error);
        });
    });
  }

  signInAnonym() {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  }

  getLastMessage(classroomId: string): Observable<Message> {
    const chatRef = this.chatsRef.doc<Message>(`${classroomId}/`);

    return chatRef.snapshotChanges().pipe(
      map(action => {
        const data = action.payload.data() as Message;
        const id = action.payload.id;
        return { id, ...data };
      })
    );
  }

  // Fetch all chats for a classroom
  getClassroomChats(classroomId: string): Observable<Chat[]> {
    const chatRef = this.chatsRef.doc(`${classroomId}`);
    return this.chatsRef.doc(classroomId).collection<Chat>('chats')
      .snapshotChanges().pipe(
        tap(actions => this.logReadCount(actions.length)), // Log read count
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Chat;
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      );
  }

  // Fetch messages for a specific chat room
  getChatMessagesAndMarkAsRead(chatId: string, userId: string, limit: number, lastMessage?: Message): Observable<Message[]> {
    // Create an observable to fetch messages
    const messagesQuery = this.db.collection<Message>(`${this.dbPath}/${chatId}/messages`, ref => {
      let query = ref.orderBy('timestamp', 'desc').limit(limit);
      if (lastMessage) {
        query = query.startAfter(lastMessage.timestamp);
      }
      return query;
    });

    // Fetch the messages and mark them as read
    return messagesQuery.snapshotChanges().pipe(
      tap(actions => this.logReadCount(actions.length)), // Log read count
      map(actions => {
        const messages: Message[] = actions.map(a => {
          const data = a.payload.doc.data() as Message;
          const id = a.payload.doc.id;
          return { id, ...data };
        });

        // TODO: check if needed
        // Mark messages as read only if they are currently unread
        // if (messages.length > 0) {
        //   const unreadMessages = messages.filter((message: any) => !message.readStatus[userId]);
        //   if (unreadMessages.length > 0) {
        //     this.markMessagesAsRead(chatId, userId).catch(error => {
        //       console.error('Failed to mark messages as read:', error);
        //     });
        //   }
        // }

        return messages;
      })
    );
  }


  // Create a new chat room
  createChatRoom(classroomId: string, participants: string[]) {
    const chat: Chat = {
      classroomId: classroomId,
      createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
      lastMessage: '',
      lastMessageAt: serverTimestamp(),
      lastMessageFrom: '',
      lastMessageFromName: '',
      lastMessageFromImage: '',
      lastMessageIsFile: false,
      lastMessageDeleted: false,
      lastMessageId: '',
      isProcessed: false,
      lastProcessedMessageId: '',
    };
    return this.chatsRef.add(chat);
  }

  // Add a new message to a chat room
  addMessage(classroomId: string, message: Message, user: any): Promise<void> {
    const chatRef = this.chatsRef.doc(`${classroomId}`);
    const messageRef = chatRef.collection('messages').doc();

    const messageId = (messageRef.ref.id);
    return firstValueFrom(chatRef.get()).then(snapshot => {
      const updateData: any = {
        createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        lastMessage: message.content,
        lastMessageAt: serverTimestamp(),
        classroomId: classroomId,
        lastMessageFrom: message.senderId,
        lastMessageFromName: this.generalService.getPersonFullName(user),
        lastMessageFromImage: this.userService.getUserPhoto(user?.avatarUrl),
        lastMessageIsFile: message.isFileFromLibrary || message.isUploadFile,
        lastMessageDeleted: false,
        lastMessageId: messageId, // Accessing id through ref
        lastProcessedMessageId: '',
      };

      if (!snapshot.exists) {
        // Retrieve users in the chat, including teacher and students
        const usersInChat = [this.currentRoom().teacher, ...this.currentRoom().classroomStudents];
        const updatedUnreadCounts: any = {};
        usersInChat.forEach((chatUser: any) => {
          if (chatUser.aspUserId !== message.senderId) {
            updatedUnreadCounts[chatUser.aspUserId] = 1; // Initialize unread count to 1 for new chat
          }
        });
        updateData.unreadCounts = updatedUnreadCounts;

        return chatRef.set(updateData).then(() => {
          return messageRef.set(message).then(() => {
            return messageRef.update({ id: messageRef.ref.id }).then(() => {
              return this.updateChatLastMessage(chatRef, classroomId);
            })
          });
        });
      } else {
        // Existing chat room, update last message and unread counts
        return this.updateLastMessage(chatRef, message, user, false, messageId).then(() => {
          return messageRef.set(message).then(() => {
            return messageRef.update({ id: messageRef.ref.id });
          })
        });
      }
    });
  }

  async sendMessageAndMarkRead(
    classroomId: string,
    message: Message,
    user: any
  ): Promise<void> {
    const chatRef = this.chatsRef.doc(`${classroomId}`);
    const messageRef = chatRef.collection('messages').doc();
    const unreadMessagesRef = this.db.collection(`${this.dbUnreadPath}/${classroomId}/messages`);

    const messageId = messageRef.ref.id;
    const batch = this.db.firestore.batch();

    // Prepare message data
    const messageData = {
      ...message,
      id: messageId,
      createdAt: moment().format('YYYY-MM-DD HH:mm:ss')
    };

    // Add new message to the batch
    batch.set(messageRef.ref, messageData);

    const chatSnapshot = await firstValueFrom(chatRef.get());
    const updateData: any = {
      lastMessage: message.content,
      lastMessageAt: serverTimestamp(),
      lastMessageFrom: message.senderId,
      lastMessageFromName: this.generalService.getPersonFullName(user),
      lastMessageFromImage: this.userService.getUserPhoto(user?.avatarUrl),
      lastMessageIsFile: message.isFileFromLibrary || message.isUploadFile,
      lastMessageDeleted: false,
      lastMessageId: messageId,
      isProcessed: false,
    };

    if (!chatSnapshot.exists) {
      // New chat room: initialize unread counts for all users, the sender's unread count to 0
      const usersInChat = [this.currentRoom().teacher, ...this.currentRoom().classroomStudents];
      const unreadCounts: { [key: string]: number } = {};
      unreadCounts[user.aspUserId] = 0;

      usersInChat.forEach(chatUser => {
        if (chatUser.aspUserId !== message.senderId) {
          unreadCounts[chatUser.aspUserId] = 1;
        }
      });

      updateData.unreadCounts = unreadCounts;
      batch.set(chatRef.ref, updateData);
    } else {
      // Update last message and adjust unread counts for existing room
      const existingData = chatSnapshot.data();
      const updatedUnreadCounts = { ...existingData?.unreadCounts };

      Object.keys(updatedUnreadCounts).forEach(userId => {
        if (userId !== message.senderId) {
          updatedUnreadCounts[userId] = (updatedUnreadCounts[userId] || 0) + 1;
        } else {
          updatedUnreadCounts[this.user.aspUserId] = 0;
        }
      });

      updateData.unreadCounts = updatedUnreadCounts;
      batch.update(chatRef.ref, updateData);
    }

    // TODO: check if needed
    // Update read status for the current user in unread messages
    // const unreadMessages = await firstValueFrom(unreadMessagesRef.get());
    // unreadMessages.forEach(doc => {  // Use .docs to access individual documents
    //   const docData = doc.data() as any;   // Retrieve data from each document
    //   const readStatus = docData.readStatus || {}; // Access or initialize readStatus

    //   if (!readStatus[user.aspUserId]) {
    //     readStatus[user.aspUserId] = true; // Mark as read for the current user
    //     batch.update(doc.ref, { readStatus }); // Add update to batch
    //   }
    // });



    // Commit the entire batch
    await batch.commit();
  }


  private updateLastMessage(chatRef: AngularFirestoreDocument<any>,
    updatedMessage: Message, user: any, isLastMessageDeleted = false, messageId = ''): Promise<void> {

    const messageRef = chatRef.collection('messages').doc();
    const updateData: any = {
      lastMessage: updatedMessage.content,
      lastMessageAt: serverTimestamp(),
      lastMessageFrom: updatedMessage.senderId,
      lastMessageFromImage: this.userService.getUserPhoto(user?.avatarUrl),
      lastMessageFromName: this.generalService.getPersonFullName(user),
      lastMessageIsFile: updatedMessage.isFileFromLibrary || updatedMessage.isUploadFile,
      lastMessageDeleted: isLastMessageDeleted,
      lastMessageId: messageId
    };

    return firstValueFrom(chatRef.get()).then(snapshot => {
      console.log('current room', this.currentRoom());
      const updatedUnreadCounts: any = {};
      // Retrieve users in the chat, including teacher and students
      const usersInChat = [this.currentRoom().teacher, ...this.currentRoom().classroomStudents];

      usersInChat.forEach((chatUser: any) => {
        if (chatUser.aspUserId !== updatedMessage.senderId) {
          updatedUnreadCounts[chatUser.aspUserId] = (snapshot!.exists ? (snapshot!.data() as any).unreadCounts?.[chatUser.aspUserId] || 0 : 0) + 1;
        }
      });

      updateData.unreadCounts = updatedUnreadCounts;
      return chatRef.update(updateData);
    });
  }

  editMessage(classroomId: string, messageId: string, updatedMessage: Message, user: any, updateLastMessage = false): Promise<void> {
    const chatRef = this.chatsRef.doc(`${classroomId}`);
    const messageRef = chatRef.collection('messages').doc(`${messageId}`);
    const messagesRef = chatRef.collection('messages', ref => ref.orderBy('timestamp', 'desc').limit(1));

    const lastMessageDoc = messagesRef.ref.doc();
    const lastMessageId = lastMessageDoc.id;
    console.log(lastMessageId);
    return messageRef.get().toPromise().then(snapshot => {
      if (snapshot!.exists) {


        return messageRef.update({
          ...updatedMessage,
        }).then(() => {
          if (updateLastMessage) {
            return this.updateLastMessage(chatRef, updatedMessage, user, false, updatedMessage.id!);
          }
          return Promise.resolve();
        });
      } else {
        throw new Error('Message does not exist');
      }
    });
  }

  getLastUnreadMessagesCountForUser(classroomId: string, userId: string): Observable<number> {
    const chatRef = this.chatsRef.doc(`${classroomId}`);

    return chatRef.valueChanges().pipe(
      tap(actions => this.logReadCount(actions?.unreadCounts?.[userId] || 0)), // Log read count
      map(data => {
        const unreadCounts = (data as any)?.unreadCounts;
        return unreadCounts ? unreadCounts[userId] || 0 : 0;
      })
    );
  }

  markLastMessagesAsRead(classroomId: string, userId: string): Promise<void> {
    const chatRef = this.chatsRef.doc(`${classroomId}`);
    return this.markLastMessagesAsReadInLastMessage(chatRef, userId);
  }

  markLastMessagesAsReadInLastMessage(chatUnreadCountsRef: AngularFirestoreDocument<any>, userId: string): Promise<void> {
    return chatUnreadCountsRef.get().toPromise().then(snapshot => {
      if (snapshot!.exists) {
        const data = snapshot!.data() as any;
        const currentCount = data.unreadCounts?.[userId] || 0;

        // Only proceed with the update if the unread count for the user is greater than zero
        if (currentCount > 0) {
          const updatedUnreadCounts = { ...data.unreadCounts, [userId]: 0 };
          return chatUnreadCountsRef.update({ unreadCounts: updatedUnreadCounts });
        }
      }
      // Resolve immediately if the document doesn't exist or no update is needed
      return Promise.resolve();
    }).catch(error => {
      console.error('Failed to mark messages as read:', error);
      return Promise.reject(error);
    });
  }

  // Delete a specific message from a chat room
  async deleteMessage(classroomId: string, message: any, user: any, updateLastMessage = false): Promise<void> {
    console.log(classroomId);
    console.log(message);

    const chatRef = this.chatsRef.doc(`${classroomId}`);
    const messageRef = chatRef.collection('messages').doc(message.id);

    // Start a batch to combine operations
    const batch = this.db.firestore.batch();

    // Queue the deletion of the message
    batch.delete(messageRef.ref);

    if (updateLastMessage) {
      const latestMessage = await this.getLatestMessage(classroomId);
      const updateData = {
        ...message,
        content: 'Message Deleted'
      };

      await this.updateLastMessageInBatch(chatRef, updateData, user, true, latestMessage?.id, batch);
    }

    // Commit the batch
    await batch.commit();

    // Update the chat's last message if not updating the last message
    if (!updateLastMessage) {
      await this.updateChatLastMessage(chatRef, classroomId);
    }
  }

  private async updateLastMessageInBatch(
    chatRef: AngularFirestoreDocument<any>,
    updatedMessage: any,
    user: any,
    isLastMessageDeleted: boolean,
    messageId: string | undefined,
    batch: any
  ): Promise<void> {
    const updateData: any = {
      lastMessage: updatedMessage.content,
      lastMessageAt: serverTimestamp(),
      lastMessageFrom: updatedMessage.senderId,
      lastMessageFromImage: this.userService.getUserPhoto(user?.avatarUrl),
      lastMessageFromName: this.generalService.getPersonFullName(user),
      lastMessageIsFile: updatedMessage.isFileFromLibrary || updatedMessage.isUploadFile,
      lastMessageDeleted: isLastMessageDeleted,
      lastMessageId: messageId
    };

    // Get snapshot of the chat document
    const snapshot = await firstValueFrom(chatRef.get());

    // Prepare updated unread counts
    const updatedUnreadCounts: any = {};
    const usersInChat = [this.currentRoom().teacher, ...this.currentRoom().classroomStudents];
    const currentUnreadCounts = snapshot.exists ? snapshot.data()?.unreadCounts || {} : {};

    usersInChat.forEach((chatUser: any) => {
      if (chatUser.aspUserId !== updatedMessage.senderId) {
        updatedUnreadCounts[chatUser.aspUserId] = (currentUnreadCounts[chatUser.aspUserId] || 0) + 1; // Increment unread count
      }
    });

    // Only update if there are changes to the unread counts
    if (Object.keys(updatedUnreadCounts).length > 0) {
      updateData.unreadCounts = updatedUnreadCounts;
    }

    // Perform a single update to the chat document in the batch
    batch.update(chatRef.ref, updateData);
  }

  private updateChatLastMessage(chatRef: AngularFirestoreDocument<any>, classroomId: string): Promise<void> {
    return this.getLatestMessage(classroomId).then((snapshot: any | null) => {

      console.log(snapshot);
      if (snapshot) {
        const latestMessage = snapshot;
        return chatRef.update({
          lastMessage: latestMessage.content,
          lastMessageAt: latestMessage.createdAt,
          lastMessageFrom: latestMessage.senderId,
          lastMessageFromName: this.generalService.getPersonFullName(this.user),
          lastMessageFromImage: latestMessage.senderImage,
          lastMessageIsFile: latestMessage.isFileFromLibrary || latestMessage.isUploadFile,
          lastMessageDeleted: false,
          lastMessageId: latestMessage.id,
        });
      } else {
        // Handle case where there are no messages left in the collection
        return chatRef.update({
          lastMessage: '',
          lastMessageAt: null,
          lastMessageFrom: '',
          lastMessageFromName: '',
          lastMessageFromImage: '',
          lastMessageIsFile: false,
          lastMessageDeleted: false,
          lastMessageId: '',
        });
      }
    });
  }

  getLatestMessage(classroomId: string): Promise<Message | null> {
    const chatRef = this.chatsRef.doc(`${classroomId}`);
    return firstValueFrom(chatRef.collection('messages', ref => ref.orderBy('timestamp', 'desc').limit(1)).get()).then(snapshot => {
      if (!snapshot.empty) {
        const latestMessage = snapshot.docs[0].data() as Message;
        const messageCount = snapshot.size; // Get the count of messages retrieved
        this.logReadCount(messageCount);

        return latestMessage;
      } else {
        return null; // No messages found
      }
    });
  }

  // Update a chat room document
  updateChatRoom(classroomId: string, data: Partial<Chat>): Promise<void> {
    return this.chatsRef.doc(classroomId).update(data);
  }

  // Delete a chat room document
  deleteChatRoom(classroomId: string): Promise<void> {
    return this.chatsRef.doc(classroomId).delete();
  }

  // TODO: check if needed
  // addUnreadMessage(classroomId: string, message: Message): Promise<void> {
  //   const messageRef = this.db.collection(`${this.dbUnreadPath}/${classroomId}/messages`).doc(message.id);

  //   // Set the initial readStatus for the new message
  //   const initialReadStatus = {
  //     // Initialize the readStatus for all users, defaulting to false (unread)
  //     [message.senderId]: false, // Mark the sender as false (unread)
  //     // You can add more user IDs here if needed, depending on your application logic
  //   };

  //   return messageRef.set({
  //     readStatus: initialReadStatus, // Add the readStatus field
  //     timestamp: serverTimestamp(), // Ensure there's a timestamp for ordering
  //   });
  // }

  // markMessagesAsRead(classroomId: string, userId: string): Promise<void> {
  //   const unreadMessagesQuery = this.db.collection(`${this.dbUnreadPath}/${classroomId}/messages`, ref =>
  //     ref.where(`readStatus.${userId}`, '==', false)
  //   );

  //   return unreadMessagesQuery.get().toPromise().then(querySnapshot => {
  //     const batch = this.db.firestore.batch();
  //     let hasUpdates = false; // Flag to track if there are any updates

  //     querySnapshot!.forEach(doc => {
  //       const data = doc.data();

  //       // Ensure readStatus is a valid map or initialize it
  //       const readStatusUpdate = (data as any).readStatus || {};

  //       // Only update if the userId is not already marked as read
  //       if (!readStatusUpdate[userId]) {
  //         readStatusUpdate[userId] = true;
  //         // Update the document with the new or modified readStatus map
  //         batch.update(doc.ref, { readStatus: readStatusUpdate });
  //         hasUpdates = true; // Set the flag to true if an update is made
  //       }
  //     });

  //     // Only commit the batch if there are updates
  //     if (hasUpdates) {
  //       return batch.commit();
  //     }

  //     // If no updates were made, resolve immediately
  //     return Promise.resolve();
  //   });
  // }



  // Mark messages as read
  markAllMessagesAsRead(classroomId: string): Promise<void> {
    return this.db.collection(`${this.dbUnreadPath}/${classroomId}/messages`).get().toPromise().then(querySnapshot => {
      const batch = this.db.firestore.batch();
      querySnapshot!.forEach(doc => {
        const messageData = doc.data() as Message;
        const updatedReadStatus = {};

        // Set readStatus for all users in the message's readStatus map to true
        // for (const userId in messageData.readStatus) {
        //   if (messageData.readStatus.hasOwnProperty(userId)) {
        //     const a = `readStatus.${userId}`;
        //     (updatedReadStatus as any)[a] = true;
        //   }
        // }

        batch.update(doc.ref, updatedReadStatus);
      });
      return batch.commit();
    });
  }

  // Get unread messages count for a user in a classroom
  getUnreadMessagesCount(classroomId: string, userId: string): Observable<number> {
    return this.db.collection<Chat>(`${this.dbUnreadPath}/${classroomId}`).snapshotChanges().pipe(
      tap(actions => this.logReadCount(actions.length)), // Log read count
      map(actions => {
        const unreadCounts = actions.length; // Get the total number of unread messages
        const unreadCountsData = actions.reduce((acc, action) => {
          const data = action.payload.doc.data() as Chat;
          return acc + (data.unreadCounts?.[userId] || 0); // Sum up unread counts for the user
        }, 0);
        return unreadCountsData; // Return the total unread count for the user
      })
    );
  }


  // Get total unread messages count for a user across all classrooms
  // getUnreadMessagesCountForUser(userId: string): Observable<number> {
  //   return this.chatsRef.snapshotChanges().pipe(
  //     tap(actions => this.logReadCount(actions.length)),
  //     switchMap(chatSnapshots => {
  //       const chatObservables = chatSnapshots.map(snapshot => {
  //         const chatId = snapshot.payload.doc.id;
  //         return this.getUnreadMessagesCount(chatId, userId);
  //       });
  //       return combineLatest(chatObservables);
  //     }),
  //     map(unreadCounts => unreadCounts.reduce((acc, count) => acc + count, 0))
  //   );
  // }

  // getTotalUnreadMessagesCountForUser(userId: string): Observable<number> {
  //   return this.chatsRef.snapshotChanges().pipe(
  //     tap(actions => this.logReadCount(actions.length)), // Log read count
  //     switchMap(chatSnapshots => {
  //       const chatIds = chatSnapshots.map(snapshot => snapshot.payload.doc.id);
  //       // Create an array of observables for each classroom's unread messages
  //       const unreadMessageObservables = chatIds.map(classroomId =>
  //         this.chatsRef.doc(classroomId).collection<Message>('messages').snapshotChanges()
  //           .pipe(
  //             map(actions => actions.filter(action => {
  //               const data = action.payload.doc.data() as Message;
  //               return !data.readStatus.hasOwnProperty(userId);
  //             }).length)
  //           )
  //       );

  //       // Combine all observables into a single observable
  //       return combineLatest(unreadMessageObservables);
  //     }),
  //     map(unreadCounts => unreadCounts.reduce((total, count) => total + count, 0))
  //   );
  // }

  // Or, to get the user object directly
  getFireBaseUser(): Observable<any> {
    return this.afAuth.authState;
  }

  setUserStatus(userId: string, status: 'online' | 'offline'): Promise<void> {
    const userRef = this.usersRef.doc(userId);
    return userRef.set({ status, lastOnlineTime: Timestamp.now() }, { merge: true });
  }

  setUserLastOnlineTime(userId: string, time: FieldValue): Promise<void> {
    const userRef = this.usersRef.doc(userId);
    return userRef.set({ status: 'online', lastOnlineTime: time }, { merge: true });
  }

  getUserStatus(userId: string): Observable<'online' | 'offline'> {
    return this.usersRef.doc<ChatUserStatus>(userId).valueChanges().pipe(
      map(user => user?.status || 'offline')
    );
  }

  /**
   * Retrieves all users in the Firestore 'users' collection, including their status compared to the last online time.
   * The status is determined by comparing the current time with the user's last online time. If the user was online
   * within the last `secondsToCheckOnlineStatus` seconds, the status is set to 'online', otherwise it is set to 'offline'.
   * If the user does not have a last online time, the status is set to 'offline'.
   * @returns An Observable of an array of ChatUserStatus objects, where each object contains the user's id, status, and last online time.
   */
  getOnlineUsersComparedToLastOnlineTime(): Observable<ChatUserStatus[]> {
    return this.db.collection<ChatUserStatus>('/users')
      .snapshotChanges().pipe(
        tap(actions => this.logReadCount(actions.length)), // Log read count
        map(actions => {
          const currentTime = Timestamp.now();
          return actions.map(a => {
            const data = a.payload.doc.data() as ChatUserStatus;
            const id = a.payload.doc.id;
            const lastOnlineTime = data.lastOnlineTime as Timestamp;
            if (lastOnlineTime) {
              if (!currentTime.seconds || !lastOnlineTime.seconds) {
                return { ...data, id, status: 'offline' };
              }
              const timeDifference = currentTime?.seconds - lastOnlineTime?.seconds;

              // Check if the user was online within the last 10 seconds
              if (timeDifference <= this.secondsToCheckOnlineStatus) {
                return { ...data, id, status: 'online' };
              } else {
                return { ...data, id, status: 'offline' };
              }
            }
            return { ...data, id, status: 'offline' };

          });
        })
      );
  }

  // TODO: check if needed
  // Fetch all chats for a classroom along with the last unread messages count
  // getClassroomChatsWithUnreadCount(classroomId: string): Observable<{ chats: Chat[], unreadCount: number }> {
  //   const chatRef = this.chatsRef.doc(classroomId).collection<Chat>('chats');
  //   const unreadCount$ = this.getUnreadMessagesCount(classroomId, this.user?.uid); // Assuming 'this.user' is defined

  //   return combineLatest([
  //     chatRef.snapshotChanges().pipe(
  //       map(actions => actions.map(a => {
  //         const data = a.payload.doc.data() as Chat;
  //         const id = a.payload.doc.id;
  //         return { id, ...data };
  //       }))
  //     ),
  //     unreadCount$
  //   ]).pipe(
  //     map(([chats, unreadCount]) => ({ chats, unreadCount }))
  //   );
  // }

  // TODO: check if needed
  // Use this method for fetching messages and marking them as read
  // getChatMessagesAndUnreadCounts(chatId: string, userId: string, limit: number): Observable<{ messages: Message[], unreadCount: number }> {
  //   const messagesQuery = this.db.collection<Message>(`${this.dbPath}/${chatId}/messages`, ref =>
  //     ref.orderBy('timestamp', 'desc').limit(limit)
  //   );

  //   const unreadCount$ = this.getUnreadMessagesCount(chatId, userId);

  //   return combineLatest([
  //     messagesQuery.snapshotChanges().pipe(
  //       map(actions => actions.map(a => {
  //         const data = a.payload.doc.data() as Message;
  //         const id = a.payload.doc.id;
  //         return { id, ...data };
  //       }))
  //     ),
  //     unreadCount$
  //   ]).pipe(
  //     map(([messages, unreadCount]) => ({ messages, unreadCount }))
  //   );
  // }

  // Method to count messages sent by a user today per chatroom
  countMessagesSentTodayByUser(userId: string, chatroomId: string): Observable<number> {
    const chatRef = this.chatsRef.doc(`${chatroomId}`).collection<Message>('messages');

    // Get the start and end timestamps for today
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0); // Start of today
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999); // End of today

    // Create an observable from the Firestore query
    return new Observable<number>(observer => {
      chatRef.ref.where('senderId', '==', userId)
        .where('timestamp', '>=', startOfDay)
        .where('timestamp', '<=', endOfDay)
        .get()
        .then(snapshot => {
          observer.next(snapshot.size); // Emit the count
          observer.complete();            // Complete the observable
        })
        .catch(error => {
          observer.error(error); // Emit an error if something goes wrong
        });
    });
  }

  logReadCount(count: number) {
    const currentCount = parseInt(localStorage.getItem('readCount') || '0', 10);
    localStorage.setItem('readCount', (currentCount + count).toString());
  }
}
