@defer {

@if (!bubbleMode()) {


<div id="notifications-overview" class="notifications-overview">
  <div class="surface-section border-round-3xl">
    <div class="block-header relative justify-content-center  lg:flex">

      <div class="absolute pl-1 md:pl-2 left-0">

        <!-- <button routerLink="/dashboard" pButton pRipple type="button" label="Back to Dashboard" icon="pi pi-angle-left" iconPos="left"
                             class="p-button-outlined p-button-sm p-button-rounded text-white" styleClass=""></button> -->
      </div>
      <span class="block-title">
        <span class="text-0">Chat</span>
      </span>
    </div>


    <div class="chat-wrapper">
      <div class="chat flex-column sm:flex-row">

        <div class="left" *ngIf="showLeftSideMobile()"
          [ngClass]="{ 'col-12': isTabletOrMobile, 'col-4': !isTabletOrMobile }">

          <div class="persona-container">


            <ng-container *ngTemplateOutlet="personSearchTemplate"></ng-container>

            <ng-container *ngTemplateOutlet="personBoxTemplate"></ng-container>


          </div>
        </div>
        <!-- ends left -->

        <div class="right sm:col-8" *ngIf="showRightSideMobile()">
          <ng-container *ngTemplateOutlet="chatPersonTemplate"></ng-container>
          <div class="chat-messages mt-1" #scrollMe [ngClass]="{ 'pointer-events-none': editMessageMode() }">
            <div class="flex align-items-center justify-content-center p-2">
              <p-button type="button" [label]="'Load previous messages'" [outlined]="true" [rounded]="true"
                [disabled]="messages().length === 0" (click)="loadMoreMessages(currentChatId)">
              </p-button>
            </div>
            <!-- @if (this.messageCache.get(this.currentChatId) && this.messageCache.get(this.currentChatId)['messages']) {
              {{this.messageCache.get(this.currentChatId)['messages'] | json}}
            } -->
            
            @if (messages().length === 0) {
            <ng-container *ngTemplateOutlet="emptyMessagesTemplate"></ng-container>
            }
            <app-chat-messages-display [messages]="messages()" [chatsList]="chatsList()"
              (deleteMessageSelected)="deleteMessage($event)"
              (editMessageSelected)="editMessage($event)"></app-chat-messages-display>

          </div>

          <ng-container *ngTemplateOutlet="inputTextTemplate"></ng-container>

        </div>
      </div>
    </div>


  </div>
</div>



}
}@placeholder {
<span>loading...</span>
}



@if (bubbleMode()) {

<div id="chat-circle" [@scaleToggle]="chatCircleState" (click)="toggleChat()" class="btn btn-raised" [pTooltip]="'Chat'"
  tooltipPosition="top">
  <i class="pi pi-comments"></i>
  <p-badge [value]="this.chatService.unreadCount()" badgeSize="small" severity="success"
    styleClass="absolute notif-badge" />

</div>

<div class="chat-box overflow-hidden border-round-lg" *ngIf="chatBoxVisible" [@scaleToggle]="chatBoxState">
  <div class="chat-box-header flex align-items-center justify-content-between">
    <span class="text-lg">Chat</span>


    <div class="flex align-items-center justify-content-center gap-3">
      <a class="flex align-items-center" title="Open Chat in maximized mode" [routerLink]="'/chat'" >
        <i class="pi pi-window-maximize" (click)="toggleChat()"></i>
      </a>
      <span class="chat-box-toggle flex align-items-center" (click)="toggleChat()"><i class="pi pi-times"></i></span>
    </div>
  </div>
  <div class="chat-box-body">
    <div class="main-screen" [ngClass]="{ 'editMode': editMessageMode(), 'showInnerChat': showInnerChat }">
      <div id="homeScreen" class="home-screen" *ngIf="!showInnerChat">

        <div id="homeScreenMessagesBody" class="home-screen-messages bg-grad-white-style">

          <div class="flex flex-column h-full px-2">
            <!-- <div class="flex align-items-center justify-content-between p-3">
              <div>
                <div class="text-900 text-xl font-medium mb-1">Conversations</div>
                <p class="text-600 m-0">{{chatsList().length}} Groups</p>
              </div>
            </div> -->

            <div class="mt-1">
              <ng-container *ngTemplateOutlet="personSearchTemplate"></ng-container>
            </div>
            <!-- <hr class="m-0 mb-1 border-bottom-1 border-200"> -->
            <div class="flex-auto overflow-y-auto pr-1 pt-2 px-2">


              @if (!showInnerChat) {
              <ng-container *ngTemplateOutlet="personBoxTemplate"></ng-container>
              }

            </div>
          </div>



        </div>
      </div>
      <div id="chatScreen" class="chat-screen" *ngIf="showInnerChat">
        <div class="chat-screen-header">
          <div class="chat-screen-header-left">
            <span id="chatBackbutton" class="back-button">
            </span>

          </div>
          <span class="search-button">
            <i class="fas fa-ellipsis-h"></i>
          </span>
        </div>


        <div class="right">

          <ng-container *ngTemplateOutlet="chatPersonTemplate"></ng-container>

          <div class="chat-messages" [ngClass]="{ 'pointer-events-none': editMessageMode() }" #scrollMe>
            <div class="flex align-items-center justify-content-center p-2">
              <p-button type="button" [label]="'Load previous messages'" [outlined]="true" [rounded]="true"
                [disabled]="messages().length === 0" (click)="loadMoreMessages(currentChatId)">
              </p-button>
            </div>
            @if (messages().length === 0) {
            <ng-container *ngTemplateOutlet="emptyMessagesTemplate"></ng-container>
            }
            <app-chat-messages-display [messages]="messages()" [chatsList]="chatsList()" [isBubbleMode]="bubbleMode()"
              (editMessageSelected)="editMessage($event)"
              (deleteMessageSelected)="deleteMessage($event)"></app-chat-messages-display>

          </div>
        </div>

      </div>
      <div id="searchScreen" class="search-screen">
        <div class="search-screen-header">
          <span id="chat-back-button" class="back-button">
            <i class="fas fa-long-arrow-alt-left"></i>
          </span>
          <input id="searchInput" type="text" placeholder="Search" class="search-input">
        </div>
        <div id="searchScreenBody" class="search-screen-body">

        </div>
      </div>
    </div>
  </div>
  <div class="chat-input" *ngIf="showInnerChat">
    <div class="chat-screen-footer">
      <div class="input">

        <ng-container *ngTemplateOutlet="inputTextTemplate"></ng-container>
      </div>
    </div>
  </div>
</div>
}


<ng-template #chatPersonTemplate>
  <div class="chat-person">
    <div class="inner border-bottom-1 border-200 relative p-2 sm:p-2 w-full">
      <!-- <ng-container *ngTemplateOutlet="chatTypeBadgeTemplate; context: { chatGroup: activeUser }"></ng-container> -->
      <div class="flex surface-border align-items-center justify-content-between">
        <div class="flex surface-border align-items-center">
          <p-button *ngIf="isTabletOrMobile || bubbleMode()" icon="pi pi-chevron-left" styleClass="text-2xl"
            (click)="showBackUsers()" severity="info" />
          <!-- <i class="pi pi-chevron-left text-3xl" (click)="showBackUsers()"></i> -->
          <div class="relative mr-2 flex align-items-center">
            <img [src]="generalService.getDomainFileNamePath(getChatGroupAvatarUrl(activeUser))"
              (error)="generalService.setDefaultUserAvatar($event)" class="border-circle"
              style="width: 46px; height: 46px;">
            @if (isUserOnline(getChatGroupAspUserId(activeUser))) {
            <span id="pn_id_26_badge" class=" online__badge"></span>
            }
          </div>
          <div class="flex flex-column align-items-start">
            <span class="text-xs sm:text-xl text-900 font-medium">{{activeUser?.chatName}}</span>
          </div>
        </div>

        <div>
          <ul class="list-none p-0 m-0 mr-2">
            <li class="mb-0">
              <div class="flex justify-content-between align-items-center gap-1">
                <span class="text-900 inline-flex justify-content-between align-items-center">
                  <i class="pi pi-arrow-down-left-and-arrow-up-right-to-center mr-2"></i>
                  <span class="font-medium text-xs text-900 flex align-items-center gap-1">
                    <i class="pi pi-info-circle text-xs ml-1 hidden sm:block"  [pTooltip]="'Number of messages you can send per day per chatroom.'"
                    tooltipPosition="top"></i>

                    <span>Messages Limit</span>

                  
                  </span>
                </span>
                <span class="text-indigo-500 font-medium">{{ currentMessageCountPerChat() }} / {{
                  totalMessageLimitPerDayPerChat }}</span>
              </div>
              <div class="surface-300 w-full mt-1" style="height: 7px; border-radius: 4px;">
                <div class="bg-indigo-500 h-full" [style.width]="progressBarWidth" style="border-radius: 4px;"></div>
              </div>
            </li>
          </ul>
        </div>

      </div>

    </div>
  </div>
</ng-template>

<ng-template #inputTextTemplate>
  @if (editMessageMode()) {
  <div
    class="w-full border-round-3xl bg-yellow-200 py-2 px-3 text-yellow-900 text-sm flex align-items-center justify-content-between px-2 py-1 font-medium mb-0">
    <div class="flex align-items-center gap-1"><i class="pi pi-pencil"></i><span>Edit Message</span></div>

    <i class="pi pi-times ml-2 cursor-pointer" (click)="disableEditAndTextarea(); resetTextarea();"></i>
  </div>
  }

  <div #textareaWrapper class="chat-input box " [ngClass]="{ 'border-round-3xl': !bubbleMode() }">

    <div style="position: relative;" class="speeddial-tooltip-demo"
      [ngClass]="{ 'pointer-events-none': editMessageMode(), 'hidden': editMessageMode() }">

      <div class="fab-container">
        <div class="fab fab-icon-holder">
          <ng-container *ngIf="uploadProgress > 0; else notUploading">
            <i class="pi pi-spin pi-spinner"></i>
          </ng-container>
          <ng-template #notUploading>
            <i class="pi pi-plus"></i>
          </ng-template>
        </div>
        <ul class="fab-options">
          <li>
            <!-- <span class="fab-label">File:</span> -->
            <div class="fab-line fab-icon-holder" (click)="openFileUploadDialog()">
              <img src="/assets/icons/folder-icon.svg" class="send-img" alt="">
            </div>
          </li>
          <li>
            <!-- <span class="fab-label">Photo</span> -->
            <div class="fab-tel fab-icon-holder">
              <ng-container *ngIf="uploadProgress > 0; else notUpload">
                <i class="pi pi-spin pi-spinner"></i>
              </ng-container>
              <ng-template #notUpload>
                <p-fileUpload mode="basic" accept="image/*" maxFileSize="4000000" (onSelect)="onBasicUploadAuto($event)"
                  [auto]="true" styleClass="p-button-rounded p-button-info purple-icon text-white">

                  <ng-template pTemplate="chooseicon">
                    <img src="/assets/icons/camera.svg" class="send-img" alt="">
                  </ng-template>


                </p-fileUpload>
              </ng-template>
            </div>
          </li>
        </ul>
      </div>

      <!-- <div class="flact__cont">
      <div class="flact">
        <button class="flact_option" data-icon="text">Text</button>
        <button class="flact_option" data-icon="link">Link</button>
        <button class="flact_button" data-icon="add">Submit</button>
      </div>
    </div> -->

      <!-- <p-speedDial buttonClassName="p-button-rounded p-button-info speed__btn" [model]="leftTooltipItems"
      className="speeddial-right" direction="up" /> -->
    </div>


    <!-- <i id="emojiBtn" class="pi pi-paperclip purple-icon" (click)="openFileUploadDialog()"></i> -->



    <textarea #autoResizeTextArea rows="1" (input)="adjustParentHeight()" (paste)="onPaste($event)" type="text"
      pInputText class="p-1 w-full mx-2" placeholder="Type a message" (focus)="onFocusTextarea()"
      (click)="autoResizeTextArea.focus(); adjustParentHeight()"
      (keydown.enter)="addNewMessage($event, autoResizeTextArea)"></textarea>
    <div class="chat-send-btn send" (click)="addNewMessage($event, autoResizeTextArea)">

      <img src="/assets/icons/paper-plane.svg" class="send-img" alt="">
    </div>
  </div>
</ng-template>


<ng-template #personSearchTemplate>
  <div class="search">
    <span class="p-input-icon-right w-full mb-1">
      <input type="text" pInputText placeholder="Search" (input)="onSearchChange($event)"
        class="h-2rem w-full input-blue gradient-blue rounded" />
      <i class="pi pi-search text-primary"></i>
    </span>
  </div>
</ng-template>

<ng-template #personBoxTemplate>
  <div class="persona box border-1 border-200" *ngFor="let chatGroup of filteredChats(); let i = index"
    (click)="setChatRoomActive(chatGroup)"
    [ngClass]="{ 'selected': chatGroup.classroomId === currentChatId && !bubbleMode() }">
    <div class="relative mr-1 flex align-items-center">
      <img [src]="generalService.getDomainFileNamePath(getChatGroupAvatarUrl(chatGroup))"
        (error)="generalService.setDefaultUserAvatar($event)" class="border-circle" style="width: 40px; height: 40px;">
      @if (isUserOnline(getChatGroupAspUserId(chatGroup))) {
      <span class=" online__badge"></span>
      }
    </div>
    @if (countLastMessageCount((lastMessages$ | async)?.[chatGroup.classroomId]) > 0) {
    <div class="new-message-count">{{countLastMessageCount((lastMessages$ | async)?.[chatGroup.classroomId])}}</div>
    }
    <!-- <img src="https://content-static.upwork.com/uploads/2014/10/02123010/profilephoto_goodcrop.jpg" /> -->
    <div class="info-section">


      <ng-container *ngTemplateOutlet="chatTypeBadgeTemplate; context: { chatGroup: chatGroup }"></ng-container>
      <span class="info-name">{{chatGroup.chatName}}</span>
      <span class="info-message">
        <ng-container *ngIf="(lastMessages$ | async)?.[chatGroup.classroomId]?.lastMessageIsFile; else notFileChat">
          @if((lastMessages$ | async)?.[chatGroup.classroomId]?.lastMessageDeleted) {
          <i class="pi pi-trash"></i> File deleted
          } @else {
          <i class="pi pi-file"></i> File
          }
        </ng-container>

        <ng-template #notFileChat>
          @if((lastMessages$ | async)?.[chatGroup.classroomId]?.lastMessageDeleted) {
          <i class="pi pi-trash"></i> Message deleted
          } @else {
          <span [innerHtml]="(lastMessages$ | async)?.[chatGroup.classroomId]?.lastMessage"></span>
          }
        </ng-template>
      </span>
    </div>
    <div class="detail-section">
      <span *ngIf="(lastMessages$ | async)?.[chatGroup.classroomId]?.lastMessage" class="detail-date">{{(lastMessages$
        | async)?.[chatGroup.classroomId]?.lastMessageAt?.seconds * 1000 |
        date:
        'short'}}</span>
    </div>
  </div>
</ng-template>

<ng-template #emptyMessagesTemplate>
  <ul class="px-1 m-0 list-none">
    <li class="flex align-items-center py-2">
      <div class="fab w-3rem h-3rem flex align-items-center justify-content-center bg-indigo-300	
         border-circle mr-3 flex-shrink-0">
        <i class="pi pi-comments text-xl text-white"></i>
      </div><span class="text-900 line-height-3 font-medium"> <span class="text-700 font-normal">
          No messages yet. Feel free to start the conversation with
          {{activeUser?.relatedName}}
          <!-- {{getChatOppositeName(activeUser)}} -->
        </span></span>
    </li>
  </ul>
</ng-template>

<ng-template #chatTypeBadgeTemplate let-chatGroup="chatGroup">
  @if (chatGroup?.chatType === chatType.CLASSROOM && chatGroup?.classroomStudents.length > 1) {
  <span class="group__badge p-badge p-component p-badge-info">Group</span>
  } @else if (chatGroup?.chatType === chatType.USER) {
  <span class="group__badge p-badge p-component p-badge-classroom">Private</span>
  } @else {
  <span class="group__badge p-badge p-component p-badge-classroom">Private</span>
  }
</ng-template>